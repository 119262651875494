import React from "react";
import styled from "@emotion/styled";
import { Image, ImageGallery } from "./Images";
import { Title, Subtitle, Paragraph } from "./Text";

const HomeContainer = styled.div`
  text-align: center;
`;

const QuestionList = styled.dl`
  text-align: left;
`;

const Question = styled.dt`
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;
`;

function Home() {
  const portraitImageScaling = "32%";

  const weddingDate = "Saturday, September 20, 2025";
  const weddingVenue = "The Meadowlands at the DCA";
  const hotel = "Courtyard Norwalk";
  const galleryImages = [
    { src: "/images/dca-outdoors-01.jpg", alt: "DCA House Outdoors" },
    { src: "/images/dca-front.jpg", alt: "DCA House Front" },
    { src: "/images/dca-bird-sanctuary.jpg", alt: "DCA Bird Santuary" },
    { src: "/images/dca-indoors-02.jpg", alt: "DCA Indoors" },
    { src: "/images/dca-outdoors-02.jpg", alt: "DCA House Outdoors Again" },
    { src: "/images/dca-staircase.jpg", alt: "DCA Indoor Staircase" },
    { src: "/images/dca-history.jpg", alt: "DCA House History" },
  ];
  return (
    <HomeContainer>
      <Title>A & M</Title>
      <Subtitle>Saturday, September 20th</Subtitle>
      <Image
        src="/images/Motto and Michaela-17.jpg"
        alt="Photo of Anthony and Michaela embracing and smiling"
      ></Image>
      <Paragraph>
        We're getting married!! After ten years together, we are finally ready
        to start the next chapter in our lives. We are excited to have all of
        you at our wedding, and are grateful for the love and support that each
        of you have shown us throughout our relationship. We can't wait to see
        you there!
      </Paragraph>
      <Subtitle>Venue</Subtitle>
      <ImageGallery images={galleryImages}></ImageGallery>
      <Paragraph>
        Our wedding will be at the beautiful Meadowlands at the DCA. The
        ceremony will take place in the lush outdoors garden area, with the
        reception to follow in their indoor reception hall.
      </Paragraph>   

      <Subtitle>Q&A</Subtitle>
        <QuestionList>
          <Question>When is the wedding?</Question>
          <dd>Our wedding will be held on {weddingDate}!</dd>
          <Question>Where is the venue?</Question>
          <dd>
            The ceremony and reception will take place at {weddingVenue}. The address
            is:
            <br />
            <br />
            <strong>
              274 Middlesex Rd
              <br />
              Darien, CT 06820
            </strong>
            <br />
            <br />
            Visit their site for more information:{" "}
            <a href="https://dariendca.org/meadowlands/">
              Meadowlands at the DCA
            </a>
          </dd>
          <Question>How can I get there from NYC without a car?</Question>
          <dd>
            Taking the Metro North out of Grand Central Terminal is likely
            the fastest way to get there. Google Maps
            <a href="https://maps.app.goo.gl/JyqjWgFxAk1uR2m36?g_st=ic">
              &nbsp;recommends these options&nbsp;
            </a>
            (as of 11/09/24).
            The first option in that list is the best, which is taking a
            New Haven line train to the Darien station. We'd recommend using
            a car service like Uber from the Darien station instead of walking.
          </dd>
          <Question>Is there a hotel block for the wedding?</Question>
          <dd>
            We have a hotel block set up at {hotel} by Marriott. The address
            is:
            <br />
            <br />
            <strong>
              Courtyard Norwalk
              <br />
              474 Main Avenue
              <br />
              Norwalk, Connecticut, 06851
            </strong>
            <br />
            <br />
            The hotel is about a 15 minute drive from the venue.
            <br />
            <br />
            You can get a discounted rate by calling 1-800-647-7578 
            and requesting the Consolini/Motto Wedding Room Block.
            You can alternatively use this link to make a reservation: {" "}
            <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1726857990052&key=GRP&guestreslink2=true">
              Group Rate for Consolini/Motto Wedding Room Block
            </a>
            <br />
            <br />
            The cut-off date for making a reservation is Friday, August 22, 2025.  
            Make sure to make reservations prior to this date! There is also a boat show that 
            weekend in Norwalk, so booking closer to the date may be tricky.
            <br />
            <br />
            For detailed instructions on travel from LGA, JFK, or HPN airports, you can: {" "}
            <a href="https://www.marriott.com/en-us/hotels/hpnnw-courtyard-norwalk/overview/"> visit this link here </a> 
            and scroll down to the "GETTING HERE" section towards the bottom of the page.    
          </dd>
          <Question>
            What is the dress code? 
          </Question>
          <dd>
            The dress will be formal attire!
          </dd>
          <Question>
            Can I wear sweatpants? 😃
          </Question>
          <dd>No. 😤</dd>
        </QuestionList>
        <br />
      </HomeContainer>
  );
}

export default Home;
